import React, { useRef } from 'react'
import { graphql } from 'gatsby'
import styles from './BrandPage.module.scss'
import cn from 'classnames'
import { Row, Col, Container, FancyShow } from '@kogk/common'
import { MainLayout, SEO } from '@cmp/site'
import { GlobalDataProvider } from '@src/data/global-data'
import { useLanguage } from '@kogk/gatsby-plugin-i18n'
import { usePreview, makeSlices } from '@kogk/gatsby-theme-prismic'
import Img from 'gatsby-image'
import sliceMapping from '@src/data/prismic-slice-mapping/page-slices'
import { ProductOverview, FeaturedCategories } from '@cmp/sections'

const colorTheme = {
  black: 'black-theme',
  blue: 'default-theme'
}

const Slices = makeSlices(sliceMapping)
const scrollToProductOverview = ref => {
  ref.current.scrollIntoView({ behavior: 'smooth' })
}

export default ({ data: staticData }) => {
  const data = usePreview(staticData)
  const {
    hero_image: heroImage,
    hero_image: {
      thumbnails: { mobile: mobileImage }
    },
    popular_categories: popularCategories,
    heading_for_popular_category_section: { text: headingForPopularCategories },
    brand_id: { text: brandId },
    brand_name: { text: brandName },
    page_color_theme: pageColorTheme
  } = data.prismicBrandPage.data
  const productOverviewRef = useRef(null)
  const { language } = useLanguage()

  const seoBrandProps = {
    ...SEO.seoPropertiesToProps(data.prismicBrandPage.data.seo_properties)
  }

  return (
    <GlobalDataProvider language={language} altUrls={[]}>
      <MainLayout isSnyrtivara={pageColorTheme === 'black'}>
        {Object.values(seoBrandProps).length > 0 && <SEO {...seoBrandProps} />}
        <Container fluid>
          <Row>
            <Col col={12} className={cn('px-0', styles.heroSection)}>
              <FancyShow>
                <Img
                  className='w-100 d-block d-md-none'
                  fluid={mobileImage.fluid}
                />
                <Img
                  className='w-100 d-none d-md-block'
                  fluid={heroImage.fluid}
                />
              </FancyShow>
            </Col>
          </Row>
        </Container>
        <article className={colorTheme[pageColorTheme]}>
          <FancyShow>
            <Slices body={data.prismicBrandPage.data.body} />
          </FancyShow>
        </article>

        <FeaturedCategories
          brandId={brandId}
          heading={headingForPopularCategories}
          categoryArr={popularCategories}
          onClick={() => scrollToProductOverview(productOverviewRef)}
        />
        <div ref={productOverviewRef}>
          <ProductOverview
            isSnyrtivara={pageColorTheme === 'black'}
            brandId={brandId}
            heading={`Allar vörur ${brandName}`}
          />
        </div>
        <article className={cn('mb-md-5 my-4', colorTheme[pageColorTheme])}>
          <FancyShow>
            <Slices body={data.prismicBrandPage.data.body1} />
          </FancyShow>
        </article>
      </MainLayout>
    </GlobalDataProvider>
  )
}

export const query = graphql`
  query genericBrandPageQuery($prismicId: ID!) {
    prismicBrandPage(prismicId: { eq: $prismicId }) {
      ...fragmentPrismicBrandPageFull
    }
  }
`
